.notice{ position: absolute; right: 20px;top: 60px;z-index: 1111; }
.notice .item {
    display: block;
    width: 300px;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0 1px 4px #111;
    background: #212020eb;
    overflow: hidden;
    cursor: pointer;
    margin: 10px 0;
    transition: all 3s ease 0s;
    opacity: 0;}
.notice .icon {float: left;  width: 50px;  height: 50px;  margin: 10px;  font-size: 50px;line-height: 51px;  text-align: center;}
.notice .name {width: 222px;font-size: 17px;font-weight: bold;position: absolute;left: 70px;display: block;margin-top: 13px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.notice .text {color: #fff;font: 12px Arial, sans-serif;width: 222px;font-weight: bold;position: absolute;left: 70px;display: block;margin-top: 37px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.notice .error {color:#f44336;}
.notice .success {color:#4caf50;}
.notice .remark {color:#FF9800;}

[status='show'] {
   opacity:1; /*Элемент полностью прозрачный (невидимый)*/
   transition: 1s; /*Скорость перехода состояния элемента*/
   animation: show-notice 1s 1; /* Указываем название анимации, её время и количество повторов*/
   animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
  /*animation-delay: 1s; /* Задержка перед началом */
}

[status='hide'] {
   opacity:1; /*Элемент полностью прозрачный (невидимый)*/
   transition: 1s; /*Скорость перехода состояния элемента*/
   animation: hide-notice 1s 1; /* Указываем название анимации, её время и количество повторов*/
   animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
  /*animation-delay: 1s; /* Задержка перед началом */
}

@keyframes show-notice{
  0%{
  opacity:0;
  }
  100% {
  opacity:1;
  }
}

@keyframes hide-notice{
  0%{
  opacity:1;
  }
  100% {
  opacity:0;
  }
}
