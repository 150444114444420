.modal.o { position: fixed;top: 0;left: 0;right: 0; bottom: 0;display: -webkit-flex;background-color: #000000b8;z-index: 12;}
.modal .b { box-shadow: 5px 7px 18px 0px #000000a8;
  background: var(--secondary);
  color: var(--secondary-text);
  border-radius: 10px;
  position: relative;
  min-width: 300px;
  margin: auto;
  border-radius: 20px;
  max-height: 90%;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 20px;
}
.modal .h { font-size: 15px;line-height: 35px; font-weight: bold;text-align: left; padding: 10px 15px 5px 30px;-webkit-user-select: none;display: flex;  z-index: 1; }
.modal .x { cursor: pointer; font-size: 20px!important;opacity: 0.5;position: absolute;  right: 10px;  top: 10px;  z-index: 2;  width: 35px;  border-radius: 5px;  text-align: center;  line-height: 36px;}
.modal .x:hover {opacity: 1;}
.modal .l {padding: 20px;border-radius: 10px;font-size: 15px;box-shadow: var(--inset);margin: 10px;overflow: hidden;overflow-y: scroll;}
.modal .d { text-align: center;  font-size: 13px; font-weight: bold;}
.modal .d.form {padding: 20px;    overflow: scroll;}
.modal .d.form dl { display: flex; margin: 0;font-size: 15px;  margin-bottom: 5px;}

[shake=true]{-webkit-animation-name:shake;animation-name:shake;-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-fill-mode:both;animation-fill-mode:both}
@keyframes shake{0%,100%{transform:translate3d(0,0,0)}10%,30%,50%,70%,90%{transform:translate3d(-10px,0,0)}20%,40%,60%,80%{transform:translate3d(10px,0,0)}}

.modal-menu {
    display: flex;
    flex: 1;
    justify-content: center;
}
.modal-empty{
    flex: 0 30px;
}

.modal-menu-link {
   padding-left: 25px;
}

.modal-menu-link:hover{
  color: #2196f3;
}

.modal-menu-link span{
   color: var(--placeholder);
   font-size: 13px;
}

.button-icons{
  text-align: right;
    position: absolute;
    right: 60px;
    align-items: center;
    display: flex;
    height: 35px;
    font-size: 20px;
}

.button-icons i{
  cursor: pointer;
  padding-left: 10px;
}

.modal [ui-field="search"] {
    display: flex;
    padding: 0px;
    min-height: 30px;
    margin: 0 8px!important;
}
