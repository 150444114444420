[data-select="backdrop"] {
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000;
}
[data-select^="background"] {
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    background: #28292d;
    z-index: 99;
    border-radius: 10px;
    overflow: hidden;
    max-height: 300px;
}
[data-select^="background"] ul {
    overflow-y: auto;
    width: 100%;
    max-height: 300px;
    display: block;
    overflow-x: hidden;
}
[data-select^="background"] li {
    display: block;
    border-bottom: 1px solid #999;
    cursor: pointer;
    display: block;
    padding: 5px 20px;
    color: #fff !important;
    border-left: 0 !important;
    text-align: left !important;
    height: 35px;
    line-height: 25px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
[data-select^="background"] li:hover {
    font-weight: bold;
}
[data-select^="background"] li:last-child {
    border-bottom: 0;
}

[data-select*="inline"] {
    margin-top: -5px;
    background: #e6ebf4;
    border-radius: 0 0 5px 5px;
    background: #394264;
    box-shadow: 5px 8px 20px #000;
}

[data-select*="simpleLogin"] {
    margin-top: 18px;
    background: #e6ebf4;
    border-radius: 5px;
    border: 1px solid #0000004d;
}
[data-select*="simpleLogin"] li {
    color: #394264 !important;
    text-align: center !important;
    padding: 10px 0;
    font-size: 16px;
}

/*border-radius: 0 0 10px 10px;*/

select-b table {
    width: 100%;
    cursor: pointer;
}
select-b table td {
    color: white;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}
select-b table th {
    height: 34px;
    line-height: 30px;
    color: #fff;
    color: #8d9298;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
}
select-b table td:hover {
    background: #646da1;
}
select-b table[view="month"] {
    height: 100%;
}
select-b table[view="month"] td {
    height: 40px;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}
select-b table[view="month"] th {
    height: 34px;
    line-height: 30px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
}

select-o {
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000;
}

select-c {
    box-shadow: 5px 7px 18px 0px #000000a8;
    padding: 10px;
    margin: 0;
    display: block;
    position: absolute;
    background: #394264;
    z-index: 99;
    border-radius: 10px;
    overflow: hidden;
    color: #fff;
}
select-c div {
    display: grid;
    grid-template-columns: 35px 35px 35px 35px 35px 35px 35px;
}
select-c b {
    font-weight: normal;
    display: block;
    padding: 8px 5px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}
select-c b:hover {
    background: #00000073;
}
select-c p {
    color: #abb6d2;
    text-align: center;
    padding: 0;
    margin: 3px;
    cursor: default;
}
select-c i {
    flex: 0 30px;
    padding: 9px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}
select-c i:hover {
    background: #00000073;
}
select-c i::before {
    cursor: pointer;
    display: block;
}
select-c s {
    display: flex;
    font-style: normal;
    text-decoration: none;
    padding-bottom: 5px;
    align-items: center;
}
select-c s h2 {
    flex: 1 1;
    padding: 0;
    margin: 0;
    font-size: 18px;
    text-align: center;
    font-weight: normal;
    line-height: 35px;
    color: #fff;
    cursor: default;
}
select-c span {
    padding: 2px;
    text-align: center;
}

select-f {
    display: block;
    border-bottom: 1px solid #999;
    cursor: pointer;
    display: block;
    padding: 7px;
    color: #fff !important;
    border-left: 0 !important;
    text-align: left !important;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    padding-left: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
select-f:before {
    content: "";
    background: #00000073;
    width: 20px;
    height: 20px;
    left: 10px;
    display: inline-block;
    border-radius: 5px;
    text-align: center;
    margin: 3px 0 0 0;
    position: absolute;
}
select-f.select:after {
    content: "";
    background: #fffffff7;
    width: 14px;
    height: 14px;
    left: 10px;
    display: inline-block;
    border-radius: 8px;
    text-align: center;
    margin: 6px 0 0 3px;
    position: absolute;
}
select-f:last-child {
    border-bottom: 0;
}
select-f:hover {
    font-weight: bold; /*background: #506290;*/
}

select-s {
    flex-direction: column;
    display: none;
}
select-s dl {
    display: flex;
    margin: 0;
    line-height: 35px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}
select-s dt {
    flex: 0 30px;
    position: relative;
}
select-s dd {
    flex: 1;
    margin-left: 5px;
}
select-s dt:after {
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background: #3f51b5;
    top: 11px;
    left: 11px;
    border-radius: 50%;
}
select-s dt:before {
    content: "";
    display: block;
    background: #ddd;
    width: 20px;
    height: 20px;
    margin: 7px;
    border-radius: 50px;
}
select-s dt[status="0"]:after {
    display: none;
}

select-p {
}
select-n {
    display: flex;
    flex-direction: column;
}
select-h {
    line-height: 30px;
    display: block;
    cursor: pointer;
}

select-h dl {
    display: flex;
    margin: 0;
    line-height: 35px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}
select-h dt {
    flex: 0 35px;
    position: relative;
}
select-h dd {
    flex: 1;
    margin-left: 5px;
    font-weight: bold;
}

select-h dt:before {
    content: "";
    transform: translate(0, -50%) rotate(-135deg);
    width: 10px;
    height: 10px;
    border-top: 3px solid #1f253d;
    border-left: 3px solid #1f253d;
    position: absolute;
    left: 12px;
    top: 15px;
}
select-r li[active="true"] select-h dt:before {
    content: "";
    transform: translate(0, -50%) rotate(45deg);
    top: 22px;
}

select-m {
    display: flex;
    padding: 10px 20px;
}
select-l {
    flex: 0 150px;
    border-right: 1px solid #ddd;
}
select-l li {
    line-height: 30px;
    cursor: pointer;
}
select-r {
    flex: 1;
    padding: 0 10px;
}
select-r li[active="true"] select-s {
    display: flex;
}

select-l li {
    display: flex;
    flex-direction: column;
}
select-z {
}

dial-m {
    -webkit-app-region: no-drag;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 40px;
    left: 85px;
    line-height: 50px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    z-index: 1;
    -webkit-user-select: none;
}
dial-h {
    -webkit-app-region: no-drag;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 40px;
    left: 20px;
    line-height: 50px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    z-index: 1;
    -webkit-user-select: none;
}
dial-d {
    -webkit-app-region: no-drag;
    width: 140px;
    height: 140px;
    overflow: hidden;
    display: block;
    position: relative;
    border: 2px solid #abb6d2;
    border-radius: 50%;
    margin: 8px;
}
dial-s {
    -webkit-app-region: no-drag;
    display: block;
    width: 100px;
    margin: auto;
    position: absolute;
    top: 62px;
    left: 0;
    right: 0;
    line-height: 50px;
    color: #abb6d2b0;
    font-weight: bold;
    font-size: 30px;
    z-index: 3;
    text-align: center;
    -webkit-user-select: none;
}
dial-a {
    -webkit-app-region: no-drag;
    width: 150px;
    height: 150px;
    left: 15px;
    top: 15px;
    position: absolute;
    background: #0000;
}
dial-a:after {
    -webkit-app-region: no-drag;
    width: 2px;
    height: 50px;
    border-radius: 2px;
    background: #1f253d;
    content: "";
    left: 74px;
    top: 25px;
    display: block;
    position: absolute;
}
dial-b {
    -webkit-app-region: no-drag;
    width: 150px;
    height: 150px;
    left: 15px;
    top: 15px;
    position: absolute;
    background: #0000;
}
dial-b:after {
    -webkit-app-region: no-drag;
    width: 2px;
    height: 40px;
    border-radius: 2px;
    background: #1f253d;
    content: "";
    left: 74px;
    top: 35px;
    display: block;
    position: absolute;
}

select-c[active="hour"] dial-a {
    z-index: 1;
}
select-c[active="time"] dial-b {
    z-index: 1;
}
select-c[active="hour"] dial-a:before {
    cursor: grab;
    z-index: 1;
    -webkit-app-region: no-drag;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    content: "";
    margin-left: 65px;
    display: block;
}
select-c[active="time"] dial-b:before {
    cursor: grab;
    z-index: 1;
    -webkit-app-region: no-drag;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    content: "";
    margin-left: 65px;
    display: block;
}

select-c {
    cursor: pointer;
}
select-c[active="hour"] [text="hour"] {
    color: #fff;
    cursor: ns-resize;
}
select-c[active="time"] [text="time"] {
    color: #fff;
    cursor: ns-resize;
}

select-c [view="month"] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 230px;
}

[modal="edit-select"] modal-f {
    width: 700px;
}
[modal="edit-select"] modal-b {
    display: flex;
    flex-direction: row;
    margin: 0 15px;
}
[modal="edit-select"] modal-l,
[modal="edit-select"] modal-r {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px;
}
[modal="edit-select"] modal-l span {
    padding: 8px;
    margin: 2px;
    border-radius: 5px;
}
[modal="edit-select"] modal-l span:hover {
    background: #e6ebf4;
    cursor: pointer;
}

select-b[view="task"] {
    background: #282e48f7;
    margin-top: 7px;
    width: 460px;
    border-radius: 5px;
}
select-b[view="task"] select-y {
    width: 460px;
}
select-b[view="task"] select-a {
    width: 460px;
}

[modal="uis"] [type="count"] {
    border-radius: 5px 0 0 5px;
}
[data-select*="uis"] li {
    padding: 5px 5px;
    text-align: center !important;
}

[modal="uis"] dd {
    line-height: 30px;
    width: 50px;
    text-align: center;
    background: #00000038;
    margin-left: 2px;
    border-radius: 0 5px 5px 0;
    height: 30px;
}

.select_open {
    display: flex;
    border-radius: 10px;
    box-shadow: var(--inset);
    margin: 10px;
    overflow: hidden;
    height: 500px;
}

.select_open ._list {
    flex: 0 300px;
    overflow-y: scroll;
    padding: 5px;
    margin: 7px;
}
.select_open ._item {
    flex: 1;
    overflow-y: scroll;
    padding: 5px;
    margin: 7px;
}
.select_open ._menu {
    cursor: pointer;
    color: #1f253d;
    padding: 0 3px;
    border-radius: 7px;
    display: flex;
    margin-bottom: 5px;
    box-shadow: 2px 2px 2px var(--shadow), -1px -1px 1px var(--shade);
}

.select_open ._menu i {
    color: #394264 !important;
    line-height: 35px;
    padding: 0 10px;
    font-size: 40px;
}

.select_open ._menu span {
    font-size: 15px;
    line-height: 35px;
    padding-left: 10px;
    flex: 1 1;
}

.select_open ._item nav {
    background: #e6ebf4;
    padding: 10px;
    border-radius: 10px;
    color: #1f253d;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    box-shadow: var(--outset);
}
.select_open ._item nav .name {
    display: flex;
}

.select_open ._item nav .name b {
    padding-left: 5px;
    cursor: pointer;
    display: block;
    flex: 1;
}

.select_open ._item nav .name i {
    font-size: 25px;
    line-height: 20px;
    font-size: 40px;
    line-height: 20px;
    cursor: pointer;
    color: #000;
    color: var(--disabled);
}

.select_open ._item nav .name i:hover {
    color: var(--text);
}

.select_open ._item li {
    display: flex;
    padding-bottom: 5px;
    line-height: 35px;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    padding: 0 10px 0 10px;
    text-align: left;
    color: #39425c;
    position: relative;
}

.select_open ._item .tab {
    display: none;
    box-shadow: var(--inset);
    border-radius: 5px;
    padding: 5px;
    margin-top: 12px;
}

.select_open ._item nav:last-child {
    margin-bottom: 0px;
}
.select_open ._item nav:hover {
    background: #dce1ef;
}
.select_open ._item li:hover {
    background: #c9d1e4d1;
    border-radius: 5px;
}
.select_open ._item [set-active="true"] .tab {
    display: block;
}

.select_edit {
    display: flex;
    border-radius: 10px;
    box-shadow: var(--inset);
    margin: 10px;
    overflow: hidden;
    flex-direction: column;
    padding: 10px;
}

.select_edit li {
    display: flex;
    flex: 1;
    padding-bottom: 5px;
    line-height: 35px;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    padding: 0 10px 0 30px;
    text-align: left;
    color: #39425c;
    position: relative;
}
.select_edit [set-active="true"] .tab {
    display: block;
}
.select_edit li:before {
    content: "";
    height: 10px;
    width: 10px;
    top: 50%;
    left: 5px;
    margin-top: -7px;
    position: absolute;
    border-width: 3px;
    border-style: solid;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.select_edit li:after {
    content: "";
    top: 50%;
    left: 10px;
    margin-top: -2px;
    position: absolute;
    border-width: 3px;
    border-style: solid;
    opacity: 0;
    border-radius: 50%;
    transition: linear 0.2s all;
}
.select_edit li[set-active="true"]:after {
    opacity: 1;
}
.select_edit .add {
    text-align: center;
    border: 2px dashed var(--shadow);
    color: var(--text);
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    opacity: 0.7;
}

.select_edit .add:hover {
    opacity: 1;
    background: #c9d1e4d1;
}

.select_edit ._item_edit {
    display: flex;
    position: relative;
}
.select_edit ._item_edit:hover {
    background: #c9d1e4d1;
    border-radius: 5px;
}
.select_edit ._item_edit .button {
    position: absolute;
    opacity: 0;
    right: 10px;
    display: flex;
    font-size: 40px;
    line-height: 35px;
    cursor: pointer;
    color: var(--shadow);
}

.select_edit ._item_edit i {
    margin-left: 5px;
}
.select_edit ._item_edit i:hover {
    color: var(--text);
}

.select_edit ._item_edit:hover .button {
    opacity: 1;
}

.select_edit ._item_edit [data-edit="true"] {
    color: #f44336;
    width: 390px;
}
.select_edit ._item_edit dd {
    height: 35px;
}

.select_edit ._item_edit [data-edit="true"][placeholder]:empty:before {
    display: block;
    color: #f44336;
    font-size: 15px;
    content: attr(placeholder);
}

.select_edit ._item_edit [data-edit="false"][placeholder]:empty:before {
    display: block;
    color: #2196f3;
    font-size: 15px;
    content: "не заполнено";
}

.select_delete {
    padding: 10px 50px;
    text-align: center;
    color: #f44336;
}

.company-select .item {
    padding: 10px;
    cursor: pointer;
    margin: 2px;
}

/*
[type=select] ul{
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0;
    list-style-type: none;
    margin: 0px;
    background: #395ba9;
    border: 1px solid #ffffff00;
    border-radius: 5px;
    margin-top: 2px;
    display: none;
    max-height: 300px;
    overflow-y: auto; }

[type=select] li{padding: 10px;cursor: pointer;color:#fff;border-bottom: 1px solid #fff;}
[type=select] li:last-child{  border-bottom: 0;  }
[type=select] li:hover{ background: #0000007a;  }
[type=select][view="mini"]{ margin: 0; }
[type=select][view="mini"] dd{margin: 0;padding: 0;border: 0;width: 67px;text-align: right; }
[type=select][view="mins"] {margin: 0; }
[type=select][view="mins"] dd{margin: 0;padding: 0;border: 0;text-align: right; }
[type=select][view="mins"] ul {margin-top: 15px;}
[type=select][view="mins"] li {padding: 3px;
    cursor: pointer;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-left: 20px;}

[type=select][view="mins"] li:last-child{  border-bottom: 0;  }



[type=select][view="mins"] li:hover{ background: #0000007a;}
*/
