[contenteditable] {
    border: 0;
    outline: 0;
}

button {
    border: 0;
    padding: 13px 30px;
    margin: auto;
    display: block;
    border-radius: var(--element-radius);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

button[active="true"] {
    background-color: #297ec1;
}
button[active="false"] {
    background-color: #0000008a;
}
button[active="wait"]:focus,
button[active="wait"]:hover {
}
button[active="wait"]:after {
    font-size: 16px;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    width: 0px;
}
button[active="wait"]:before {
    content: "Подождите";
    font-size: 16px;
}
button[active="none"] {
    display: none;
}

.form {
    padding: 30px;
}

.form-string [ui-field] {
    display: flex;
}
.form-string [ui-field] dt {
    text-align: left;
    color: #999;
    padding-top: 12px;
}
.form-string [ui-field] dd {
}

.dt-30 dt {
    width: 30px;
}

.dt-100 dt {
    width: 100px;
}

[ui-field="search"] {
    border-radius: var(--element-radius);
    line-height: 20px;
    font-size: 15px;
    min-height: 44px;
    margin: 8px !important;
    width: 100%;
    display: flex;
    font-family: inherit;
    position: relative;
    overflow: hidden;
    background: var(--secondary-element);
    color: var(--secondary-icon);
    align-items: center;
}

[ui-field="search"] i {
    width: 50px;
    text-align: center;
}

[ui-field="search"] div {
    flex: 1;
    position: relative;
}

[ui-field="select"] .--input {
    cursor: pointer;
}

[ui-component="button-icon"] {
    margin: 8px;
    display: flex;
    border-radius: var(--element-radius);
    width: 44px;
    font-size: 20px;
    cursor: pointer;
}

.modal [ui-component="button-icon"] {
    margin: 0 5px;
    padding: 0 8px;
}

[ui-component].secondary {
    background: var(--secondary-element);
    border: 1px solid var(--secondary-element-border);
    color: var(--secondary-icon);
}

.primary .--input,
.primary button {
    background: var(--primary-element);
    border: 1px solid var(--primary-element-border);
    color: var(--primary-icon);
}
.primary button {
    color: var(--primary-text);
}

.secondary .--input,
.secondary button {
    background: var(--secondary-element);
    border: 1px solid var(--secondary-element-border);
    color: var(--secondary-icon);
}

.field-select-menu {
    background: #000000de;
    color: #fff;
    border-radius: var(--element-radius);
    z-index: 20;
    overflow: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 300px;
}

.field-select-menu label {
    display: block;
    line-height: 30px;
    padding: 5px;
    cursor: pointer;
    font-size: 13px;
    padding-left: 15px;
}
.field-select-menu label:hover {
    background: #2196f3d1;
}

[ui-component="button-icon"] i {
    margin: auto;
}

[ui-field] {
    padding: 8px 0px;
    margin: 0 10px;
    overflow: hidden;
}

[ui-field] dt {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    padding-top: 2px;
    padding-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--placeholder);
    font-weight: 400;
}

[ui-field] .--input {
    border-radius: var(--element-radius);
    line-height: 20px;
    font-size: 15px;
    min-height: 44px;
    margin: 0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    box-shadow: none;
    outline: 0;
    padding: 11px;
    font-family: inherit;
    position: relative;
    user-select: text;
    word-break: break-word;
    /*  text-overflow: ellipsis;*/
}

[ui-field] .--files {
    border-radius: var(--element-radius);
    line-height: 20px;
    font-size: 15px;
    min-height: 44px;
    margin: 0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    box-shadow: none;
    outline: 0;
    padding: 11px;
    font-family: inherit;
    position: relative;
    -webkit-user-select: text;
    user-select: text;
    word-break: break-word;
    display: flex;
    background: var(--secondary-element);
    border: 1px solid var(--secondary-element-border);
    color: var(--secondary-icon);
}

[ui-field] .--files label {
    flex: 1;
    cursor: pointer;
    text-overflow: ellipsis;
}

[ui-field] [type="file"] {
    display: none;
}

[ui-field="string"] .--input {
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

[ui-field].primary .--input {
}

[ui-toogle] {
    cursor: pointer;
    display: block;
    transition: all 0.3s linear 0s;
    width: 28px;
    height: 9px;
    position: relative;
    background: #dce1e6;
    border-radius: 45px;
}
[ui-toogle]:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #aeb7c2;
    position: absolute;
    top: -3px;
    transition: all 0.2s linear;
}
[ui-toogle="blue"][data="1"]:after {
    background: #5281b8;
    transform: translate3d(13px, 0px, 0);
}
[ui-toogle="green"][data="1"]:after {
    background: #4caf50;
    transform: translate3d(13px, 0px, 0);
}

[ui-field].secondary dd {
    background: var(--secondary-element);
    border: 1px solid var(--secondary-element-border);
    color: var(--secondary-text);
}

.form-outline [ui-field] {
    margin: auto;
}

.form-outline button {
    width: 100%;
}

[ui-field="text"] {
    white-space: break-spaces;
}

[ui-field="password"] {
    position: relative;
}
[ui-field="password"][secret="true"] .--input {
    -webkit-text-security: disc;
    -webkit-user-select: text;
    color: var(--text);
}
[ui-field="password"] i {
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    position: absolute;
    color: #999;
    font-size: 20px;
    right: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
    display: block;
}

[ui-field="links"] {
    display: flex;
    justify-content: space-around;
    flex: 1;
}

[ui-link] {
    color: var(--placeholder);
    cursor: pointer;
}

[ui-link] i {
}

[placeholder]:empty:before {
    -webkit-text-security: none;
    content: attr(placeholder);
    color: var(--placeholder);
    cursor: text;
}

[placeholder]:focus:before {
    content: "";
}

.form-group-header {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: var(--secondary-icon);
    justify-content: space-between;
}

.form-group-button {
    background: #f44336;
    font-weight: normal;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
}

.form-grups-button {
    display: flex;
}

.form-grups-button div {
    flex: 1;
    padding: 0 10px;
}

.field-markdown-section {
    width: 100%;
    display: block;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    border-radius: var(--element-radius);
    background: var(--secondary-element);
    border: 1px solid var(--secondary-element-border);
    min-width: 368px;
}

.field-markdown-section .--none {
    display: none;
}

.field-markdown-menu {
    height: 40px;
    border-bottom: 0;
    padding-left: 4px;
    display: flex;
    padding-right: 4px;
}

.field-markdown-menu .icon {
    line-height: inherit;
}

.field-markdown-menu i:before {
    font-weight: bold !important;
}

.field-markdown-menu span {
    line-height: 30px;
    text-align: center;
    margin: 5px 1px;
    padding: 0px;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 7px;
    cursor: pointer;
    color: #aeb7c2;
}

.field-markdown-menu span:hover {
    color: #5381b8;
}

.field-markdown-menu .icon-io-save-filled:before {
    font-weight: normal !important;
    color: #f44336;
    font-size: 16px;
}

.field-markdown-menu .--group-button {
    margin-right: 8px;
    padding-right: 8px;
    display: inline;
    height: 30px;
}

.field-markdown-menu .--empty {
    flex: 1;
}

.field-markdown-textarea {
    line-height: 20px;
    font-size: 15px;
    min-height: 200px;
    margin: 0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    box-shadow: none;
    outline: 0;
    color: var(--secondary-icon);
    padding: 11px;
    font-family: inherit;
    position: relative;
    -webkit-user-select: text;
    user-select: text;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow-x: auto;
    resize: none;
    border: 0;
    background: #0000;
    border-top: 2px solid #fff;
}

.field-markdown-view {
    position: relative;
    border-radius: 0 0 8px 8px;
    line-height: 20px;
    font-size: 15px;
    min-height: 400px;
    margin: 0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    box-shadow: none;
    outline: 0;
    color: var(--secondary-icon);
    padding: 11px;
    border-top: 2px solid #fff;
    font-family: inherit;
    position: relative;
    -webkit-user-select: text;
    user-select: text;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow-x: auto;
    resize: none;
}

.field-markdown-content {
    display: flex;
}

.field-markdown-view p {
    padding: 2px;
}
.field-markdown-view ul {
    padding: 10px 20px;
}
.field-markdown-view ol {
    padding: 10px 20px;
}

.field-markdown-section.for-fullscreen {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100% !important;
    background: #fff;
    overflow: scroll;
}

.field-markdown-section.for-fullscreen .field-markdown-menu {
    border-radius: 0;
    border: 0;
}

.field-markdown-section.for-fullscreen .field-markdown-textarea {
    min-height: calc(100vh - 41px);
    border-radius: 0;
    border: 0;
    border-top: 1px solid #0000001f;
    border-right: 1px solid #0000001f;
}

.field-markdown-section.for-fullscreen .field-markdown-view {
    min-height: calc(100vh - 41px);
    border-radius: 0;
    border: 0;
    border-top: 1px solid #0000001f;
}

.field-markdown-content:focus {
    text-overflow: unset;
    border-color: #3f8ae0;
}

[ui-field="prizes"] dt {
    display: flex;
    justify-content: space-between;
}
[ui-field="prizes"] dt i {
    cursor: pointer;
}

[ui-field="prizes"] .save {
    color: #f44336;
    font-weight: bold;
    cursor: pointer;
}

.prizes-list {
    border-radius: var(--element-radius);
    border: 1px solid var(--secondary-element-border);
    min-height: 50px;
    min-width: 400px;
}

.prizes-list-item {
    min-height: 70px;
    margin: 5px;
    display: flex;
    position: relative;
}

.prizes-list-item:last-child {
    border-bottom: 0;
}

.prizes-list-image {
    background: var(--secondary-element);
    background-size: cover;
    background-position: center;
    flex: 0 60px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.prizes-list-text {
    background: var(--secondary-element);
    margin: 5px;
    flex: 1;
    border-radius: 10px;
    padding: 10px;
}

.prizes-list-count {
    background: var(--secondary-element);
    margin: 5px;
    flex: 0 1 50px;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    line-height: 40px;
    font-size: 25px;
}

.prizes-list-text:empty::after {
    content: "Описание приза";
    color: var(--placeholder);
}

.prizes-list-action {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 20px;
    color: var(--placeholder);
    display: none;
    opacity: 0.5;
    padding: 5px;
    border-radius: 10px;
}

.prizes-list-action i {
    cursor: pointer;
}

.prizes-list-item:hover .prizes-list-action {
    display: flex;
}

.prizes-list-text:active .prizes-list-action {
    display: none;
}

.s255x64 canvas {
    position: relative;
    border: 1px dotted #0000001f;
    border-radius: 15px;
    transform: scale(0.5);
    margin: 0px 0px;
}

.s255x64 .presentation {
    height: 64;
}

.icon-load .s255x64 .edit__markup {
    width: 255px;
    height: 64px;
    margin: auto;
    left: 0;
    right: 0;
}

.icon-load .s1530x384 {
    margin: 10px 20px;
}

.icon-load .s1530x384 .edit__markup {
    width: 765px;
    height: 192px;
    margin: auto;
    left: 0;
    right: 0;
}

.icon-load .s1530x384 canvas {
    border: 1px dotted #0000001f;
    border-radius: 15px;
    transform: none;
    margin: auto;
}

.icon-load .s200x200 {
    margin: 10px 20px;
}

.icon-load .s200x200 .edit__markup {
    width: 400px;
    height: 400px;
    margin: auto;
    left: 0;
    right: 0;
}

.icon-load .s200x200 canvas {
    border: 1px dotted #0000001f;
    border-radius: 15px;
    transform: none;
    margin: auto;
}

.icon-load .s1280x720 {
    margin: 10px 20px;
}

.icon-load .s1280x720 .edit__markup {
    width: 640px;
    height: 360px;
    margin: auto;
    left: 0;
    right: 0;
}

.icon-load .s1280x720 canvas {
    border: 1px dotted #0000001f;
    border-radius: 15px;
    transform: none;
    margin: auto;
}

.icon-load .s1500x500 {
    margin: 10px 20px;
}

.icon-load .s1500x500 .edit__markup {
    width: 750px;
    height: 250px;
    margin: auto;
    left: 0;
    right: 0;
}

.icon-load .s1500x500 canvas {
    border: 1px dotted #0000001f;
    border-radius: 15px;
    transform: none;
    margin: auto;
}

.sku-list {
    border-radius: var(--element-radius);
    border: 1px solid var(--secondary-element-border);
    min-height: 50px;
    min-width: 400px;
}

.sku-list-item {
    margin: 5px;
    display: flex;
    position: relative;
}

.sku-list-item:last-child {
    border-bottom: 0;
}

.sku-list-image {
    background: var(--secondary-element);
    background-size: cover;
    background-position: center;
    flex: 0 60px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.sku-list-text {
    background: var(--secondary-element);
    margin: 5px;
    flex: 1;
    border-radius: 10px;
    padding: 10px;
}

.sku-list-text:empty::after {
    content: "Описание приза";
    color: var(--placeholder);
}

.sku-list-action {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 20px;
    color: var(--placeholder);
    display: none;
    opacity: 0.5;
    padding: 5px;
    border-radius: 10px;
}

.sku-list-action i {
    cursor: pointer;
}

.sku-list-item:hover .sku-list-action {
    display: flex;
}

.sku-list-text:active .sku-list-action {
    display: none;
}

.sku-list-item [ui-field="select"] .--input {
    padding: 7px 0;
    min-height: 20px;
    width: 30px;
}

.code-company {
    font-size: 50px;
    height: 50px;
    margin: 20px auto;
    text-align: center;
    padding-top: 20px;
}

.form-outline--company {
    & dd {
        padding: 0 !important;
    }
}
