.alerts{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: #0000009e;
  z-index: 30;
  align-content: center;
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.alerts .--body{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.alerts .--item{
   margin: auto;
   font-family: -apple-system, BlinkMacSystemFont;
   -webkit-font-smoothing: antialiased;
   text-rendering: optimizelegibility;
   box-sizing: border-box;
   font-size: 17px;
   overflow: hidden;
   width: 270px;
   height: auto;
   background-color: var(--primary);
   border-radius: 12px;
   border: 0;
   height: fit-content;
   z-index: 1;
}
.alerts .--form{
   font-family: -apple-system, BlinkMacSystemFont;
   -webkit-font-smoothing: antialiased;
   text-rendering: optimizelegibility;
   box-sizing: border-box;
   font-size: 17px;
   background-color: var(--primary-element);
   padding: 20px;
   border-bottom: 1px solid var(--primary-element);
}
.alerts .--name{
  font-family: -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  box-sizing: border-box;
  font-size: 17px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 22px;
  margin: 0 0 5px;
}
.alerts .--text {
  font-family: -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  box-sizing: border-box;
  font-size: 13px;
  margin: 0px;
  text-align: center;
  line-height: 16px;
}
.alerts .--send{
  font-family: -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  box-sizing: border-box;
  font-size: 17px;
  padding: 10px;
  cursor: pointer;
  color: #007aff;
  text-align: center;
  font-weight: 600;
  z-index: 1;
}
.alerts .--send:hover{
  background: #00000014;
}
