@font-face {
	font-family: "iconfont";
	font-display: block;
	src: url("iconfont.eot?6ba1a966936be2a5914b13b4d70229d2?#iefix") format("embedded-opentype"),
url("iconfont.woff2?6ba1a966936be2a5914b13b4d70229d2") format("woff2"),
url("iconfont.woff?6ba1a966936be2a5914b13b4d70229d2") format("woff");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: iconfont !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-io-add-outline:before {
	content: "\f101";
}
.icon-io-at-outline:before {
	content: "\f102";
}
.icon-io-bag-add-outline:before {
	content: "\f103";
}
.icon-io-bag-check-outline:before {
	content: "\f104";
}
.icon-io-bag-remove-outline:before {
	content: "\f105";
}
.icon-io-build-outline:before {
	content: "\f106";
}
.icon-io-call-outline:before {
	content: "\f107";
}
.icon-io-checkmark-circle-outline:before {
	content: "\f108";
}
.icon-io-close-circle-outline:before {
	content: "\f109";
}
.icon-io-code-slash-outline:before {
	content: "\f10a";
}
.icon-io-contract-outline:before {
	content: "\f10b";
}
.icon-io-download-outline:before {
	content: "\f10c";
}
.icon-io-expand-outline:before {
	content: "\f10d";
}
.icon-io-eye-off-outline:before {
	content: "\f10e";
}
.icon-io-eye-outline:before {
	content: "\f10f";
}
.icon-io-grid-outline:before {
	content: "\f110";
}
.icon-io-move-outline:before {
	content: "\f111";
}
.icon-io-reader-outline:before {
	content: "\f112";
}
.icon-io-reorder-four-outline:before {
	content: "\f113";
}
.icon-io-save-outline:before {
	content: "\f114";
}
.icon-io-search-outline:before {
	content: "\f115";
}
.icon-io-storefront-outline:before {
	content: "\f116";
}
.icon-io-swap-horizontal-outline:before {
	content: "\f117";
}
.icon-io-swap-vertical-outline:before {
	content: "\f118";
}
.icon-io-trash-outline:before {
	content: "\f119";
}
.icon-io-add-circle-filled:before {
	content: "\f11a";
}
.icon-io-add-filled:before {
	content: "\f11b";
}
.icon-io-alarm-filled:before {
	content: "\f11c";
}
.icon-io-alert-circle-filled:before {
	content: "\f11d";
}
.icon-io-apps-filled:before {
	content: "\f11e";
}
.icon-io-arrow-undo-filled:before {
	content: "\f11f";
}
.icon-io-at-circle-filled:before {
	content: "\f120";
}
.icon-io-bag-handle-filled:before {
	content: "\f121";
}
.icon-io-browsers-filled:before {
	content: "\f122";
}
.icon-io-business-filled:before {
	content: "\f123";
}
.icon-io-call-filled:before {
	content: "\f124";
}
.icon-io-caret-back-filled:before {
	content: "\f125";
}
.icon-io-caret-forward-filled:before {
	content: "\f126";
}
.icon-io-chatbox-ellipses:before {
	content: "\f127";
}
.icon-io-chatbubbles-filled:before {
	content: "\f128";
}
.icon-io-checkmark-circle-filled:before {
	content: "\f129";
}
.icon-io-close-circle-filled:before {
	content: "\f12a";
}
.icon-io-close-filled:before {
	content: "\f12b";
}
.icon-io-code-slash-filled:before {
	content: "\f12c";
}
.icon-io-copy-filled:before {
	content: "\f12d";
}
.icon-io-dice-filled:before {
	content: "\f12e";
}
.icon-io-disc-filled:before {
	content: "\f12f";
}
.icon-io-document-attach-filled:before {
	content: "\f130";
}
.icon-io-document-text-filled:before {
	content: "\f131";
}
.icon-io-eye-filled:before {
	content: "\f132";
}
.icon-io-eye-off-filled:before {
	content: "\f133";
}
.icon-io-flame-filled:before {
	content: "\f134";
}
.icon-io-gift-filled:before {
	content: "\f135";
}
.icon-io-globe-filled:before {
	content: "\f136";
}
.icon-io-help-circle-filled:before {
	content: "\f137";
}
.icon-io-information-circle-filled:before {
	content: "\f138";
}
.icon-io-key-filled:before {
	content: "\f139";
}
.icon-io-layers-filled:before {
	content: "\f13a";
}
.icon-io-megaphone-filled:before {
	content: "\f13b";
}
.icon-io-notifications-filled:before {
	content: "\f13c";
}
.icon-io-notifications-off-filled:before {
	content: "\f13d";
}
.icon-io-paper-plane-filled:before {
	content: "\f13e";
}
.icon-io-pause-filled:before {
	content: "\f13f";
}
.icon-io-people-circle-filled:before {
	content: "\f140";
}
.icon-io-people-filled:before {
	content: "\f141";
}
.icon-io-person-circle-filled:before {
	content: "\f142";
}
.icon-io-person-filled:before {
	content: "\f143";
}
.icon-io-pie-chart-filled:before {
	content: "\f144";
}
.icon-io-play-filled:before {
	content: "\f145";
}
.icon-io-power-filled:before {
	content: "\f146";
}
.icon-io-qr-code-filled:before {
	content: "\f147";
}
.icon-io-reader-filled:before {
	content: "\f148";
}
.icon-io-receipt-filled:before {
	content: "\f149";
}
.icon-io-refresh-filled:before {
	content: "\f14a";
}
.icon-io-reload-filled:before {
	content: "\f14b";
}
.icon-io-ribbon-filled:before {
	content: "\f14c";
}
.icon-io-rocket-filled:before {
	content: "\f14d";
}
.icon-io-save-filled:before {
	content: "\f14e";
}
.icon-io-settings-filled:before {
	content: "\f14f";
}
.icon-io-speedometer-filled:before {
	content: "\f150";
}
.icon-io-storefront-filled:before {
	content: "\f151";
}
.icon-io-sync-circle-filled:before {
	content: "\f152";
}
.icon-io-sync-filled:before {
	content: "\f153";
}
.icon-io-ticket-filled:before {
	content: "\f154";
}
.icon-io-tv-filled:before {
	content: "\f155";
}
.icon-io-wallet-filled:before {
	content: "\f156";
}
.icon-fa-bold-light:before {
	content: "\f157";
}
.icon-fa-code-light:before {
	content: "\f158";
}
.icon-fa-h1-light:before {
	content: "\f159";
}
.icon-fa-h2-light:before {
	content: "\f15a";
}
.icon-fa-h3-light:before {
	content: "\f15b";
}
.icon-fa-italic-light:before {
	content: "\f15c";
}
.icon-fa-list-ol-light:before {
	content: "\f15d";
}
.icon-fa-list-ul-light:before {
	content: "\f15e";
}
.icon-fa-eye-dropper-solid:before {
	content: "\f15f";
}
.icon-fa-eye-solid:before {
	content: "\f160";
}
.icon-fa-lips-solid:before {
	content: "\f161";
}
.icon-fa-mask-solid:before {
	content: "\f162";
}
.icon-fa-paint-brush-solid:before {
	content: "\f163";
}
.icon-fa-palette-solid:before {
	content: "\f164";
}
.icon-fa-spinner-solid:before {
	content: "\f165";
}
.icon-fa-tshirt-solid:before {
	content: "\f166";
}
.icon-brands-facebook-square:before {
	content: "\f167";
}
.icon-brands-instagram:before {
	content: "\f168";
}
.icon-brands-odnoklassniki:before {
	content: "\f169";
}
.icon-brands-skype:before {
	content: "\f16a";
}
.icon-brands-telegram-plane:before {
	content: "\f16b";
}
.icon-brands-viber:before {
	content: "\f16c";
}
.icon-brands-vk:before {
	content: "\f16d";
}
.icon-brands-whatsapp:before {
	content: "\f16e";
}
.icon-brands-youtube:before {
	content: "\f16f";
}
